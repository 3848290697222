
import Vue from 'vue';

const prefixCls = 'z-panel';

export default Vue.extend({
  name: 'ZPanel',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      prefixCls,
      showHeaderRight: true,
    };
  },
  mounted() {
    this.showHeaderRight = this.$slots.headerRight !== undefined;
  },
});
