
import Vue from 'vue';
import NoData from '@/components/kit/no-data/';
import RenderTd from './render-td';

const prefixCls = 'z-table';

export default Vue.extend({
  name: 'ZTable',
  components: {
    NoData,
    RenderTd,
  },
  filters: {
    stylesTh(col) {
      return {
        width: col.width,
        minWidth: col.minWidth,
        maxWidth: col.maxWidth,
        textAlign: col.align,
      };
    },
    stylesTd(col) {
      return {
        width: col.width,
        minWidth: col.minWidth,
        maxWidth: col.maxWidth,
        textAlign: col.align,
      };
    },
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: true,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'default',
    },
    height: Number,
    loading: Boolean,
  },
  data() {
    return {
      prefixCls,
    };
  },
});
