
import Vue from 'vue';

export default Vue.extend({
  name: 'Pagenation',
  props: {
    cur: {
      type: [String, Number],
      required: true,
    },
    totalEle: {
      type: [String, Number],
      default: 0,
    },
    all: {
      type: [String, Number],
      required: true,
    },
    callback: {
      type: Function,
      default() {
        return function callback() {
          // todo
        };
      },
    },
  },
  computed: {
    indexs() {
      let left = 1;
      let right = this.all;
      const ar = [];
      if (this.all >= 11) {
        if (this.cur > 5 && this.cur < this.all - 4) {
          left = this.cur - 5;
          right = this.cur + 4;
        } else if (this.cur <= 5) {
          left = 1;
          right = 10;
        } else {
          right = this.all;
          left = this.all - 9;
        }
      }
      while (left <= right) {
        ar.push(left);
        left++;
      }
      return ar;
    },
  },
  methods: {
    btnClick(page) {
      if (page !== this.cur) {
        this.callback(page);
      }
    },
  },
});
