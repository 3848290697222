
import Vue from 'vue';

import Icon from '@/components/base/icon/';

export default Vue.extend({
  name: 'NoData',
  components: {
    Icon,
  },
  props: {
    size: {
      type: [Number, String],
      default: 150,
    },
    color: {
      type: String,
      default: '#ccc',
    },
  },
});
